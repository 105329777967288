<style>
.row {
  font-family: "Noto Sans KR", "Malgun Gothic", "맑은 고딕", "Apple Gothic",
    "Apple SD Gothic Neo", sans-serif !important;
  color: #333;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:focus,
.nav-tabs > li.active > a:hover {
  color: #333;
  padding: 12px 40px;
  border-top: 3px solid #103674;
  border-radius: 0;
  border-bottom-color: none;
}
</style>
<template v-if="this.$root.campus">
  <div class="row info-page">
    <!--
    <div class="col-md-12" style="height: 160px; margin-bottom: 40px">
      <img
        src="../assets/images/banner_sub_notice.png"
        style="border-bottom: 2px solid #103674; width: 100%"
      />
    </div>
    -->
    <div
      class="col-md-offset-3 col-md-6"
      style="min-height: 500px; text-align: center"
    >
      <h5 style="font-size: 32px; color: #333" id="map">
        원장님 <span style="font-weight: bold; font-size: 32px">인사말</span>
      </h5>
      <div class="col-md-4" style="margin-top: 20px">
        <img
          src="../assets/images/sample.png"
          style="width: 100%; border-radius: 10px"
        />
      </div>
      <div class="col-md-8" style="margin-top: 20px; text-align: left">
        <p style="white-space: pre-wrap">{{ this.$root.campus.intro }}</p>
        <div style="text-align: right; margin-top: 30px">
          {{ this.$root.campus.name }}
          <span style="font-weight: bold"> 원장</span>
        </div>
      </div>
      <div
        class="col-md-12"
        style="
          margin-top: 20px;
          text-align: center;
          font-size: 20px;
          border-top: 1px dashed #ccc;
          padding-top: 40px;
        "
      >
        <h5 style="font-size: 32px; color: #333">
          찾아 오시는 <span style="font-weight: bold; font-size: 32px">길</span>
        </h5>
      </div>

      <div
        class="col-md-12"
        style="clear: both; color: #fff"
        v-if="this.$root.campus.address1"
      >
        <l-map
          style="
            width: 100%;
            height: 440px;
            border-radius: 10px;
            border: 1px solid #ccc;
          "
          v-model="zoom"
          v-model:zoom="zoom"
          :center="center"
        >
          <l-tile-layer
            url="https://mt0.google.com/vt/lyrs=m&hl=ko&x={x}&y={y}&z={z}"
            :attribution="attribution"
            maxZoom="16"
            minZoom="3"
          ></l-tile-layer>
          <l-control-layers />
          <l-marker :lat-lng="markerLatLng"></l-marker>
        </l-map>
      </div>
      <div class="col-md-12" style="margin-top: 20px; text-align: left">
        <p
          style="
            font-weight: bold;
            color: #103674;
            border-bottom: 2px solid #103674;
            padding-bottom: 10px;
          "
        >
          ChildU {{ this.$root.campus.name }} 주소
        </p>
        <p>{{ this.$root.campus.address1 }} {{ this.$root.campus.address2 }}</p>
      </div>

      <div class="col-md-12" style="text-align: left"></div>
    </div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import { LMap, LGeoJson, LTileLayer, LMarker } from "@vue-leaflet/vue-leaflet";
export default {
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
    LMarker,
  },
  data: () => ({
    geojson: {
      type: "FeatureCollection",
      features: [
        // ...
      ],
    },
    geojsonOptions: {
      // Options that don't rely on Leaflet methods.
    },
    url: "http://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}",
    attribution:
      '&copy; <a target="_blank" href="https://maps.google.com/maps?ll=36.1358642,128.0785804&amp;z=13&amp;t=m&amp;hl=ko-KR&amp;gl=US&amp;mapclient=apiv3" title="Google 지도에서 이 지역을 보려면 클릭하세요." ><img alt="" src="https://maps.gstatic.com/mapfiles/api-3/images/google4.png" draggable="false"></a>',
    zoom: 16.8,
    center: [37.484, 126.957],
    markerLatLng: [47.31322, -1.319482],
    bounds: null,
    map: null,
  }),
  async mounted() {
    while (true) {
      if (!this.$root.campus.address1) {
        await new Promise((resolve) => setTimeout(resolve, 500));
      } else {
        break;
      }
    }
    // HERE is where to load Leaflet components!
    const { circleMarker } = await import("leaflet/dist/leaflet-src.esm");

    // And now the Leaflet circleMarker function can be used by the options:
    this.geojsonOptions.pointToLayer = (feature, latLng) =>
      circleMarker(latLng, { radius: 8 });

    var address = await fetch(
      "https://nominatim.openstreetmap.org/search?format=json&q=" +
        this.$root.campus.address1
    );
    var json = await address.json();
    console.log(json[0]);

    this.mapIsReady = true;
    this.center = json[0];
    this.markerLatLng = json[0];
  },
};
</script>
